import * as React from "react"
import Button from "../../common/button/button"
import { imageBackgroundStyle } from "../../const/image"
import { Links } from "../../const/links"
import scrollTo from "gatsby-plugin-smoothscroll"
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"

const CtaA = ({lang = languages.EN}) => {
  const [text, setText] = React.useState();
  React.useEffect(() => {
    setText(() => {return require(`../../translations/landing/${lang}.json`).ctaa})
  }, [])

  return (
    text &&
    <div
      className="card cta-a-card"
      id="cta-a"
      style={imageBackgroundStyle("card-gradients-cta-a")}
    >
      <div className="container cta-a">
        <div className="grid-container">
          <div className="grid-template-columns cta-a-contents">
              <h2 className="light" style={{ marginTop: "0", marginBottom: 20 }}>
                {t(text.title)}
              </h2>
              <h5 className="light" style={{ marginTop: 20 }}>
                {t(text.subtitle)}
              </h5>
             
              <div className="grid-template-2-columns buttons">
                <Button
                  className="button button-light"
                  text={t(text.button1)}
                  onClick={() => scrollTo("#contact")}
                />
                <Button
                  className="button button-dark"
                  text={t(text.button2)}
                  onClick={() => window.open(Links.calendly[lang])}
                />
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CtaA
