import * as React from "react"

import Landing from "../components/landing/landing"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { organizationSchema, faqSectionSchema } from "../const/schemas.pl"
import { graphql } from "gatsby"
import { languages } from "../const/languages"

const IndexPage = ({ data }) => <Landing data={data} lang={languages.PL} />

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Wirtualna przymierzalnia na każdy ekran!"
        description="Rozwiązania AR-Labs.io przynoszą korzyści dla Twojego biznesu dzięki narzędziu, które pomaga zmniejszyć liczbę zwrotów i zwiększyć sprzedaż, umożliwiając klientom wirtualne przymierzanie akcesoriów w AR."
        canonicalURL="https://staging-area.ar-labs.io/pl"
      />
      <script type="application/ld+json">{organizationSchema}</script>
      <script type="application/ld+json">{faqSectionSchema}</script>
    </>
  )
}

export const BlogContentQuery = graphql`
  query BlogPageQuery {
    allWpPost {
      nodes {
        id
        title
        date
        categories {
          nodes {
            name
          }
        }
        excerpt
        featuredImage {
          node {
            publicUrl
          }
        }
        content
        author {
          node {
            name
            avatar {
              height
              width
              url
            }
          }
        }
      }
    }
  }
`
export default IndexPage
