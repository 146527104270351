import * as React from "react"
import Button from "../../common/button/button"
import { imageBackgroundStyle } from "../../const/image"
import messenger from "../../assets/images/svg/Messenger.svg"
import { Links } from "../../const/links"
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"

const CtaB = ({lang = languages.EN}) => {
  const [text, setText] = React.useState();
  React.useEffect(() => {
    setText(() => {return require(`../../translations/landing/${lang}.json`).ctab})
  }, [])

  return (
    text &&
    <div
      className="card cta-b-card"
      id="cta-b"
      style={imageBackgroundStyle("card-gradients-cta-b")}
    >
      <div className="container cta-b">
        <div className="grid-container">
          <div className="cta-b-contents">
            <div>
              <h3 className="light" style={{ margin: 0 }}>
                {t(text.title)}
              </h3>
              </div>
              <Button
                style={{ margin: 'auto', width: '100%' }}
                  className="button-dark button-medium"
                  text={t(text.button)}
                  name="chat with us"
                  icon={messenger}
                  onClick={() => window.open(Links.messenger)}
                />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CtaB
