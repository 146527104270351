import * as React from "react"
import Header from "./header"
import Cover from "./cover"
import CookiesPrompt from "./cookiesPrompt"
import { checkFormat } from "../../common/checkFormat"
import scrollTo from "gatsby-plugin-smoothscroll"
import BackToTopButton from "./backToTopButton"
import CtaA from "./ctaA"
import CtaB from "./ctaB"

const Benefits = React.lazy(() => import("./benefits"))
const Products = React.lazy(() => import("./products"))
const Platforms = React.lazy(() => import("./platforms"))
// const Numbers = React.lazy(() => import("./numbers"))
const Services = React.lazy(() => import("./services"))
// const CaseStudies = React.lazy(() => import("./caseStudies/caseStudies"))
const Testimonials = React.lazy(() => import("./testimonials/testimonials"))
const Contact = React.lazy(() => import("./contact/contact"))
// const Newsletter = React.lazy(() => import("./newsletter"))
const Team = React.lazy(() => import("./team"))
const FAQ = React.lazy(() => import("./faq"))
const Footer = React.lazy(() => import("./footer"))
// const Blog = React.lazy(() => import("./blog"))
const Possibilities = React.lazy(() => import("./possibilities"))

const Landing = ({ data, lang }) => {
  const [loader, setLoader] = React.useState(true)
  const [currId, setCurrId] = React.useState("")

  React.useEffect(() => {
    if (window) {
      checkFormat().then(result => {
        window.localStorage.setItem("images-extension", result)
        const hash = window.location.hash;
        if(hash){
          setCurrId(hash)
          scrollTo(hash)
        }
        setLoader(false)
      })
    }
  }, [loader])

  React.useEffect(() => {
    if (currId) {
      scrollTo(`${currId}`)
    }
  }, [currId])

  return (
    <>
      {!loader ? (
        <>
          <div id="top" />
          <Header lang={lang}/>
          <div className="content">
            <CookiesPrompt lang={lang}/>
            <Cover lang={lang}/>
            <Benefits lang={lang}/>
            <Products lang={lang}/>
            <CtaA lang={lang}/>
            <Platforms lang={lang}/>
            <Services lang={lang}/>
            <CtaB lang={lang}/>
            <Possibilities lang={lang}/>
            {/* <Numbers /> */}
            {/* <CaseStudies /> */}
            <Testimonials lang={lang}/>
            {/* <Newsletter /> */}
            <Team lang={lang}/>
            {/* <Blog allPostsData={data} /> */}
            <FAQ lang={lang} />
            <Contact lang={lang} />
            <Footer  lang={lang} />
            <BackToTopButton  lang={lang} />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default Landing
