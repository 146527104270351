import * as React from "react"
import { navigate } from "gatsby"
import Button from "../../common/button/button"
import { imageBackgroundStyle } from "../../const/image"
import { Links } from "../../const/links"
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"
import { navigateLink } from "../../common/navigateLink"

const Cover = ({lang = languages.EN}) => {
  const [text, setText] = React.useState();
  React.useEffect(() => {
    setText(() => {return require(`../../translations/landing/${lang}.json`).cover})
  }, [])

  return (
    text && 
    <div className="card" id="cover">
      <div
        className="card cover-card"
        style={imageBackgroundStyle("landing-cover-background")}
        loading="eager"
      >
        <div className="container">
          <div className="grid-container">
            <div className="title-space">
              <div>
                <h1
                  className="light"
                  style={{ fontSize: "80px", lineHeight: "88px" }}
                >
                 {t(text.title)}
                </h1>
              </div>
              <div>
              <h4 className='light'>
              {t(text.subtitle)}
              </h4>
              </div>
            </div>
            <div className="full-width">
              <Button
                className="button-dark button-medium"
                text={t(text.button)}
                onClick={() => navigateLink("/demo/3d-ar-solution", lang)}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  )
}

export default Cover
