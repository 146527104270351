export const organizationSchema = JSON.stringify({
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "AR-Labs.io",
  description: "Rozwiązania programowe w zakresie rozszerzonej rzeczywistości dla producentów, dystrybutorów i sklepów z branży okularowej",
  url: "https://ar-labs.io/",
})

export const faqSectionSchema = JSON.stringify({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Czym jest AR?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Rozszerzona rzeczywistość (ang. AR - Augmented reality) łączy świat rzeczywisty ze światem wirtualnym, zapewniając użytkownikom interaktywne wrażenia w czasie rzeczywistym. Rozszerzona rzeczywistość polega na dodawaniu elementów cyfrowych (np. modele 3D/2D) do obrazu “na żywo”, za pomocą urządzeń takich jak smartfony, komputery itp."
      },
    },
    {
      "@type": "Question",
      name: "Jaki sprzęt jest wykorzystywany w technologii rozszerzonej rzeczywistości?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Użytkownicy nie potrzebują specjalnego sprzętu, aby uzyskać dostęp do rozszerzonej rzeczywistości. Wystarczy w tym celu posiadać jedno z powszechnie używanych urządzeń, takich jak smartfon lub laptop, z których korzysta większość z nas. Nie masz pewności, czy Twój sprzęt obsługuje rozszerzoną rzeczywistość?",
      },
    },
    {
      "@type": "Question",
      name: "Jak rozszerzona rzeczywistość jest wykorzystywana w biznesie?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Rozszerzona rzeczywistość może być wykorzystywana przez firmy, aby umożliwić klientom interakcję z oferowanymi usługami lub produktami. Firmy, które wykorzystują AR, zwiększają zaangażowanie swoich klientów, wzmacniają świadomość marki, zwiększają sprzedaż, a także zyskują świetny sposób na wyróżnienie się na tle konkurencji.",
      },
    },
    {
      "@type": "Question",
      name: "Czym jest wirtualna przymierzalnia (ang. Virtual try-on)?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Wirtualna przymierzalnia to rodzaj technologii, która pozwala użytkownikom przymierzyć w czasie rzeczywistym wirtualne wersje produktów. Można w ten sposób przymierzyć produkty, takie jak np.: okulary, biżuterię, nakrycia głowy i inne. Korzystanie z wirtualnej przymierzalni pozwala sprawdzić, czy i jak będzie do nas pasował prawdziwy produkt.",
      },
    },
    {
      "@type": "Question",
      name: "Jakie problemy rozwiązuje wirtualna przymierzalnia?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Wirtualne przymierzalnie mogą być doskonałą odpowiedzią na wyzwania, z jakimi boryka się obecnie Twoja firma. Niezależnie od tego, czy mowa o nieodpowiedniej prezentacji produktu, wysokiej liczbie zwrotów, niezadowalających wynikach sprzedaży lub innych wskaźnikach, udowodnimy Ci, że wirtualna przymierzalnia rozwiązuje niemal każdy problem!",
      },
    },
  ],
})
