import * as React from "react"
import Button from "../../common/button/button"

const BackToTopButton = () => {
  const displayOnScroll = () => {
    if (
      document.body.scrollTop > 300 ||
      (document.documentElement.scrollTop > 300 &&
        document.querySelector(".back-to-top-button"))
    ) {
      document.querySelector(".back-to-top-button").style.display = "block"
    } else if (document.querySelector(".back-to-top-button")) {
      document.querySelector(".back-to-top-button").style.display = "none"
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  window.onscroll = () => displayOnScroll()

  return (
    <div>
      <div>
        <Button
          className="back-to-top-button"
          id="back-top-btn"
          name="back to top"
          onClick={() => scrollToTop()}
        />
      </div>
    </div>
  )
}

export default BackToTopButton
